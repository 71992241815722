import React from "react";
import "./style.css";
import { NicheForgotPassword } from "niche-ui-component";
import { userJwtResetPasswordUrl } from "../common/endpoints";
import { HEADERS } from "../common/constants";
import { Col, Layout, Row } from "antd";
import { Content } from "antd/es/layout/layout";

const ForgotPassword = () => {
  const handleSubmit = () => {
    console.log("Forgot Password Submit");
  };

  return (
    <Layout className="form-layout">
      <Content className="flex-layout">
        <div className="form">
          <p className="form-title">{HEADERS.FORGOT_PASSWORD}</p>
          <Row justify={"center"}>
            <Col span={24} className="mb1">
              <NicheForgotPassword
                handleSubmit={handleSubmit}
                url={userJwtResetPasswordUrl}
              />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default ForgotPassword;
