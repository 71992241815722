import { Card, Col, Row } from "antd";
import Layout from "antd/es/layout/layout";
import "./style.css";
import { HEADERS, FORMS } from "../common/constants";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu } from "../../reducers/authSlice";

const Profile = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const dispatch = useDispatch();
  const { email, firstName, lastName, phoneNumber, roles, organisations } =
    userDetails;

  const name = `${firstName} ${lastName}`;
  const orgs = `${organisations?.[0]?.name} - ${organisations?.[0]?.type}`;

  useEffect(() => {
    dispatch(setMenu(""));
  });

  return (
    <div className="user-profile">
      <Layout className="user-profile-layout">
        <Row justify={"center"} className="profile-content-card">
          <Col xs={24} sm={24} md={14} lg={12} xl={8}>
            <Card title={HEADERS.PROFILE}>
              <p>
                <span className="profile-card-label">
                  {FORMS.PROFILE.NAME}:
                </span>
                <span>{name}</span>
              </p>
              <p>
                <span className="profile-card-label">
                  {FORMS.PROFILE.EMAIL}:
                </span>
                <span>{email}</span>
              </p>
              <p>
                <span className="profile-card-label">
                  {FORMS.PROFILE.ORGANISATION}:
                </span>
                <span>{orgs}</span>
              </p>
              <p>
                <span className="profile-card-label">
                  {FORMS.PROFILE.ROLE}:
                </span>
                <span>{roles?.[0]}</span>
              </p>
              <p>
                <span className="profile-card-label">
                  {FORMS.PROFILE.PHONE_NUMBER}:
                </span>
                <span>{phoneNumber}</span>
              </p>
            </Card>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

export default Profile;
