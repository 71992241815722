import React, { useEffect } from "react";
import Routing from "./Routing";
import { BrowserRouter } from "react-router-dom";
import Notification from "./components/common/Notification";
import { useOrganization } from "./OrganizationProvider";

function App() {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const organization = useOrganization();
  const currentOrganisation =
    organization.name || userDetails?.currentOrganisation?.name;

  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = `${organization.s3BucketBaseUrl}/favicon.ico`;
    }

    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute(
        "content",
        `${currentOrganisation} Digital Vehicle Twin Platform`
      );
    }

    document.title = currentOrganisation;
  }, [currentOrganisation, organization]);
  return (
    <BrowserRouter>
      <Notification />
      <Routing />
    </BrowserRouter>
  );
}

export default App;
