import { useEffect } from "react";
import { vehiclePartAttriburesUrl } from "../common/endpoints";
import { getApis } from "../common/api";
import { Empty, Table } from "antd";

const AttributesTab = (props) => {
  const authToken = localStorage.getItem("authToken");
  const { vehiclePartId, attributesData, setAttributesData } = props;

  const columns = [
    {
      title: "Name",
      dataIndex: "attributeName",
    },
    {
      title: "Value",
      dataIndex: "attributeValue",
    },
  ];

  const getAttributesDetails = async () => {
    await getApis(
      vehiclePartAttriburesUrl,
      { partModelId: vehiclePartId[0] },
      authToken
    )
      .then((response) => {
        let attrData = [];
        if (response.data.length !== 0) {
          attrData = response.data.map((element, index) => {
            return {
              key: index,
              attributeName: element.name,
              attributeValue: element.value,
            };
          });
        }
        setAttributesData(attrData);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (vehiclePartId.length !== 0) {
      getAttributesDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclePartId]);

  return (
    <>
      {attributesData.length !== 0 ? (
        <Table
          className="attribute-table"
          columns={columns}
          dataSource={attributesData}
          size="middle"
          pagination={{
            pageSize: 6,
          }}
        />
      ) : (
        <Empty className="empty-value" />
      )}
    </>
  );
};

export default AttributesTab;
