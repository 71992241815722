import {
  Col,
  Layout,
  Row,
  Select,
  Tabs,
  Empty,
  Descriptions,
  Spin,
} from "antd";
import "./style.css";
import { useEffect, useState } from "react";
import { getApis } from "../common/api";
import {
  organisationsVehicleUrl,
  organisationVehicleInfoUrl,
  vehicleModelsUrl,
  vehicleModelInfoUrl,
  vehicleModelTreeView,
} from "../common/endpoints";
import TreeView from "./treeView";
import ManufacturesTab from "./manufacturersTab.js";
import AttributesTab from "./attributesTab.js";

const VehicleModel = () => {
  const authToken = localStorage.getItem("authToken");
  const [organisations, setOrganisations] = useState([]);
  const [partsModel, setPartsModel] = useState([]);
  const [orgDetails, setOrgDetails] = useState([]);
  const [vehicleModelDetails, setVehicleModelDetails] = useState([]);
  const [treeViewData, setTreeviewData] = useState([]);
  const [orgValue, setOrgValue] = useState("");
  const [vehicleModelValue, setVehicleModelValue] = useState("");
  const [treeViewSelectedValue, setTreeViewSelectedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [manufacturerData, setManufacturerData] = useState([]);
  const [attributesData, setAttributesData] = useState([]);

  const getOrganisations = async () => {
    await getApis(
      organisationsVehicleUrl,
      { organisationType: "MANUFACTURER", status: true },
      authToken
    )
      .then((response) => {
        const orgOptions = [];
        response?.data.forEach((element) => {
          orgOptions.push({
            value: element.id,
            label: <span>{element.name}</span>,
          });
        });
        setOrganisations(orgOptions);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrganisationsDetails = async () => {
    await getApis(organisationVehicleInfoUrl + `/${orgValue}`, {}, authToken)
      .then((response) => {
        setOrgDetails([
          { key: 1, label: "Name", children: response.data.name },
          { key: 2, label: "E-mail", children: response.data.email },
          { key: 3, label: "PhoneNumber", children: response.data.phoneNumber },
          { key: 4, label: "Description", children: response.data.description },
        ]);
      })
      .catch((error) => console.log(error));
  };

  const getVehicleModel = async () => {
    await getApis(vehicleModelsUrl, { orgId: orgValue }, authToken)
      .then((response) => {
        const partsModelOptions = [];
        response.data.forEach((element) => {
          partsModelOptions.push({
            value: element.id,
            label: <span>{element.name}</span>,
          });
        });
        setPartsModel(partsModelOptions);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (orgValue) {
      getOrganisationsDetails();
      getVehicleModel();
      setVehicleModelDetails([]);
      setVehicleModelValue("");
      setTreeviewData([]);
      setTreeViewSelectedValue("");
      setManufacturerData([]);
      setAttributesData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgValue]);

  const getVehicleModelDetails = async () => {
    setIsLoading(true);
    setTreeviewData([]);
    await getApis(
      vehicleModelInfoUrl,
      { id: vehicleModelValue, page: 0, size: 1, sort: [] },
      authToken
    )
      .then((response) => {
        const vehicleModelDetails = response.data;
        if (vehicleModelDetails.length > 0) {
          setVehicleModelDetails([
            { key: 1, label: "Name", children: vehicleModelDetails[0].name },
            {
              key: 2,
              label: "Part Type",
              children: vehicleModelDetails[0].partType,
            },
            {
              key: 3,
              label: "Gross Weight",
              children:
                vehicleModelDetails[0].grossWeight +
                vehicleModelDetails[0].weightUom,
            },
            {
              key: 4,
              label: "Manufacturer Name",
              children: vehicleModelDetails[0].manufacturerName,
            },
            {
              key: 5,
              label: "Description",
              children: vehicleModelDetails[0].description,
            },
          ]);
        }
      })
      .catch((error) => console.log(error));
    setIsLoading(false);
  };

  const getTreeViewDetails = async () => {
    setTreeViewSelectedValue("");
    setManufacturerData([]);
    setAttributesData([]);
    await getApis(vehicleModelTreeView, { vehicleModelId: vehicleModelValue }, authToken)
      .then((response) => {
        setTreeViewSelectedValue([response?.data?.id] || []);
        setTreeviewData([response?.data] || []);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (vehicleModelValue) {
      getVehicleModelDetails();
      getTreeViewDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleModelValue]);

  const handleChangeOrg = (e) => {
    setOrgValue(e);
  };

  const handleChangeModel = (e) => {
    setVehicleModelValue(e);
  };

  const handleTreeViewSelect = (e) => {
    if(!isNaN(+ e[0])){
      setTreeViewSelectedValue(e);
    }
  };

  return (
    <Layout>
      <Row gutter={[32, 32]} justify="center" className="pb15">
        <Col
          span={11}
          className="head vehicle-model-orgs bg-white border-radius mr1"
        >
          <Select
            data-testid="org-select"
            placeholder="ORG"
            className="orgs-select"
            onChange={handleChangeOrg}
            options={organisations}
          />
          <div className="org-details">
            {orgDetails?.length !== 0 ? (
              <>
                <Descriptions items={orgDetails} />
              </>
            ) : (
              <Empty />
            )}
          </div>
        </Col>
        <Col
          span={11}
          className="head vehicle-model-orgs bg-white border-radius"
        >
          <Select
            data-testid="vehicle-select"
            placeholder="VEHICLE-MODEL"
            className="orgs-select"
            onChange={handleChangeModel}
            options={partsModel}
            value={vehicleModelValue ? vehicleModelValue : null}
          />
          {!isLoading ? (
            <div className="org-details">
              {vehicleModelDetails?.length !== 0 ? (
                <>
                  <Descriptions items={vehicleModelDetails} />
                </>
              ) : (
                <Empty />
              )}
            </div>
          ) : (
            <Spin className="spinner" />
          )}
        </Col>
      </Row>
      <Row
        gutter={[32, 32]}
        justify="center"
        className="vehicle-part-tree-view"
      >
        <Col span={7} className="head  bg-white border-radius pt1 mr1">
          <div className="mb1">Tree View</div>
          {treeViewData.length !== 0 ? (
            <TreeView
              handleTreeViewSelect={handleTreeViewSelect}
              treeViewSelectedValue={treeViewSelectedValue}
              treedata={treeViewData}
            />
          ) : (
            <Empty className="empty-value" />
          )}
        </Col>
        <Col span={15} className="head border-radius tabs-main">
          <Tabs
            className="tabs-card"
            type="card"
            items={[
              {
                label: "Manufacturers",
                key: "1",
                children: (
                  <ManufacturesTab
                    vehiclePartId={treeViewSelectedValue}
                    setManufacturerData={setManufacturerData}
                    manufacturerData={manufacturerData}
                  />
                ),
              },
              {
                label: "Attributes",
                key: "2",
                children: (
                  <AttributesTab
                    vehiclePartId={treeViewSelectedValue}
                    setAttributesData={setAttributesData}
                    attributesData={attributesData}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </Layout>
  );
};
export default VehicleModel;
