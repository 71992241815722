import { Input, Select, Checkbox, Button, Form } from "antd";
import { useState } from "react";
import { ORGANISATION_TYPE, FORMS, BUTTONS } from "../common/constants";
import BusinessToCustomerForm from "./businessToCustomerForm";

const CreateOrganistionForm = ({ handleSubmit, isLoading }) => {
  const [validationStatus, setValidationStatus] = useState({
    phoneNumber: true,
    postalCode: true,
  });

  const [orgType, setOrgType] = useState("");
  const { Option } = Select;

  const getFormFieldRule = (field, pattern, errorMessage) => [
    {
      required: true,
      message: `Please Enter ${field}!`,
    },
    {
      pattern,
      message: errorMessage,
    },
  ];

  const linkOrganisationId = ["1", "2", "3", "4", "5"];

  const organisationType = [
    ORGANISATION_TYPE.ADMINISTRATOR,
    ORGANISATION_TYPE.MANUFACTURER,
    ORGANISATION_TYPE.DEALER,
    ORGANISATION_TYPE.B2BCUSTOMER,
    ORGANISATION_TYPE.B2CCUSTOMER,
  ];

  const formFields = [
    {
      label: FORMS.ORGANISATIONS.FIELDS.NAME.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.NAME.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.NAME.LABEL),
      style: {},
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.EMAIL.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.EMAIL.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.EMAIL.LABEL),
      style: {},
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.PHONE_NUMBER.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.PHONE_NUMBER.NAME,
      rules: getFormFieldRule(
        FORMS.ORGANISATIONS.FIELDS.PHONE_NUMBER.LABEL,
        /^[0-9]{10}$/,
        FORMS.VALIDATION_ERRORS.PHONE_NUMBER_DIGIT
      ),
      style: {},
      help: !validationStatus.phoneNumber
        ? FORMS.VALIDATION_ERRORS.PHONE_NUMBER_VALID
        : null,
      keyType: FORMS.ORGANISATIONS.FIELDS.PHONE_NUMBER.NAME,
      hasFeedback: true,
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.DESCRIPTION.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.DESCRIPTION.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.DESCRIPTION.LABEL),
      style: {},
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.ADDRESS1.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.ADDRESS1.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.ADDRESS1.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.ADDRESS2.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.ADDRESS2.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.ADDRESS2.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.CITY.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.CITY.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.CITY.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.ADMIN_AREA.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.ADMIN_AREA.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.ADMIN_AREA.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.POSTAL_CODE.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.POSTAL_CODE.NAME,
      rules: getFormFieldRule(
        FORMS.ORGANISATIONS.FIELDS.POSTAL_CODE.LABEL,
        /^[0-9]+$/,
        FORMS.VALIDATION_ERRORS.POSTAL_CODE
      ),
      style: { display: "inline-block", width: "50%" },
      help: !validationStatus.postalCode
        ? FORMS.VALIDATION_ERRORS.POSTAL_CODE
        : null,
      keyType: FORMS.ORGANISATIONS.FIELDS.POSTAL_CODE.NAME,
      hasFeedback: true,
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.COUNTRY.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.COUNTRY.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.COUNTRY.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
  ];

  const handleKeyPress = (event, keyType) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const pattern = /^[0-9]+$/;
    let isValid = pattern.test(keyValue);

    if (keyType === "phoneNumber") {
      setValidationStatus({ ...validationStatus, phoneNumber: isValid });
    } else if (keyType === "postalCode") {
      setValidationStatus({ ...validationStatus, postalCode: isValid });
    } else {
      isValid = true;
    }

    if (!isValid) {
      event.preventDefault();
    }
  };

  const handleOrgTypeChange = (e) => {
    setOrgType(e);
  };

  return (
    <Form onFinish={handleSubmit} autoComplete="off" className="mt2">
      <Form.Item
        name={FORMS.ORGANISATIONS.FIELDS.ORGANISATION_TYPE.NAME}
        label={FORMS.ORGANISATIONS.FIELDS.ORGANISATION_TYPE.LABEL}
        rules={getFormFieldRule(
          FORMS.ORGANISATIONS.FIELDS.ORGANISATION_TYPE.LABEL
        )}
      >
        <Select
          placeholder={FORMS.ORGANISATIONS.FIELDS.ORGANISATION_TYPE.PLACEHOLDER}
          onSelect={handleOrgTypeChange}
          value={orgType}
        >
          {organisationType.map((type, index) => (
            <Option key={index} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {orgType === "B2CCUSTOMER" ? (
        <BusinessToCustomerForm
          getFormFieldRule={getFormFieldRule}
          validationStatus={validationStatus}
          handleKeyPress={handleKeyPress}
        />
      ) : (
        <>
          {formFields.map((field, index) => (
            <Form.Item
              key={index}
              label={field.label}
              name={field.name}
              rules={field.rules}
              style={field.style}
              help={field.help}
            >
              <Input onKeyPress={(e) => handleKeyPress(e, field.keyType)} />
            </Form.Item>
          ))}

          <Form.Item
            name={FORMS.ORGANISATIONS.FIELDS.LINK_ORGANISATION_ID.NAME}
            label={FORMS.ORGANISATIONS.FIELDS.LINK_ORGANISATION_ID.LABEL}
            rules={getFormFieldRule(
              FORMS.ORGANISATIONS.FIELDS.LINK_ORGANISATION_ID.LABEL
            )}
          >
            <Select
              placeholder={
                FORMS.ORGANISATIONS.FIELDS.LINK_ORGANISATION_ID.PLACEHOLDER
              }
            >
              {linkOrganisationId.map((id, index) => (
                <Option key={index} value={id}>
                  {id}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={FORMS.ORGANISATIONS.FIELDS.IS_COMMERCIAL.LABEL}
            name={FORMS.ORGANISATIONS.FIELDS.IS_COMMERCIAL.NAME}
            valuePropName="checked"
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Form.Item
            name={FORMS.ORGANISATIONS.FIELDS.URL_SLUG.NAME}
            label={FORMS.ORGANISATIONS.FIELDS.URL_SLUG.LABEL}
            rules={getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.URL_SLUG.LABEL)}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={FORMS.ORGANISATIONS.FIELDS.IS_LOGIN_ENABLED.LABEL}
            name={FORMS.ORGANISATIONS.FIELDS.IS_LOGIN_ENABLED.NAME}
            valuePropName="checked"
          >
            <Checkbox></Checkbox>
          </Form.Item>
        </>
      )}

      <Button type="primary" htmlType="submit" loading={isLoading}>
        {BUTTONS.CREATE}
      </Button>
    </Form>
  );
};

export default CreateOrganistionForm;
