export const userApiBaseUrl = process.env.REACT_APP_USER_API_BASE_URL;
export const vehicleApiBaseUrl = process.env.REACT_APP_VEHICLE_API_BASE_URL;

export const organisationsVehicleUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_ORGANISATIONS_URL;

export const organisationVehicleInfoUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_ORGANISATION_INFO_URL;

export const vehicleModelsUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_ERP_VEHICLE_MODELS_URL;

export const vehicleModelInfoUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_ERP_VEHICLE_MODEL_INFO_URL;

export const vehiclePartManufacturersUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_ERP_VEHICLE_MANUFACTURER_URL;

export const vehiclePartAttriburesUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_ERP_VEHICLE_ATTRIBUTES_URL;

export const vehicleModelTreeView =
  vehicleApiBaseUrl + process.env.REACT_APP_ERP_VEHICLE_TREEVIEW_URL;

export const userJwtLoginUrl =
  userApiBaseUrl + process.env.REACT_APP_USER_JWT_LOGIN_URL;

export const userJwtRegisterUrl =
  userApiBaseUrl + process.env.REACT_APP_JWT_REGISTER_URL;

export const userJwtRegisterSchemaUrl =
  userApiBaseUrl + process.env.REACT_APP_JWT_REGISTER_SCHEMA_URL;

export const userJwtResetPasswordUrl =
  userApiBaseUrl + process.env.REACT_APP_JWT_RESET_PASSWORD_URL;

export const jwtConfirmUrl =
  userApiBaseUrl + process.env.REACT_APP_JWT_CONFIRM_EMAIL_URL;

export const userJwtLoginSchemaUrl =
  userApiBaseUrl + process.env.REACT_APP_USER_JWT_LOGIN_SCHEMA_URL;

export const userOrganisationInfoUrl =
  userApiBaseUrl + process.env.REACT_APP_ORGANISATION_INFO_URL;

export const organisationsUrl =
  userApiBaseUrl + process.env.REACT_APP_ORGANISATIONS_URL;

export const createOrganisationUrl =
  userApiBaseUrl + process.env.REACT_APP_CREATE_ORGANISATION_URL;

export const updateOrganisationStatusUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_UPDATE_ORGANISATIONS_STATUS_URL;

export const dashboardsUrl =
  userApiBaseUrl + process.env.REACT_APP_DASHBOARDS_URL;

export const mapDashboardToOrgUrl =
  userApiBaseUrl + process.env.REACT_APP_MAP_DASHBOARD_URL;

export const republishAllDashboardUrl =
  userApiBaseUrl + process.env.REACT_APP_REPUBLISH_ALL_DASHBOARD_URL;

export const republishDashboardUrl =
  userApiBaseUrl + process.env.REACT_APP_REPUBLISH_DASHBOARD_URL;
