import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Notification = () => {
  return (
    <ToastContainer
      autoClose={2000}
      theme={"colored"}
      hideProgressBar={true}
      draggable={false}
    />
  );
};
export default Notification;
