import axios from "axios";

export const authorizedHeader = (authToken) => {
  if (authToken) {
    return {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };
  } else {
    return { "Content-Type": "application/json" };
  }
};

export const getApis = async (url, params, authToken) => {
  try {
    return await axios({
      method: "get",
      url: url,
      params: params,
      headers: authorizedHeader(authToken),
    });
  } catch (e) {
    throw e;
  }
};

export const postApis = async (url, body, authToken) => {
  try {
    return await axios({
      method: "post",
      url: url,
      data: body,
      headers: authorizedHeader(authToken),
      json: true,
    });
  } catch (e) {
    throw e;
  }
};

export const putApis = async (url, body, authToken) => {
  try {
    return await axios({
      method: "put",
      url: url,
      data: body,
      headers: authorizedHeader(authToken),
      json: true,
    });
  } catch (e) {
    throw e;
  }
};