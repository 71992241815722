import { useEffect } from "react";
import { vehiclePartManufacturersUrl } from "../common/endpoints";
import { getApis } from "../common/api";
import { Descriptions, Empty } from "antd";

const ManufacturesTab = (props) => {
  const authToken = localStorage.getItem("authToken");
  const { vehiclePartId, manufacturerData, setManufacturerData } = props;

  const getManufacturersDetail = async () => {
    await getApis(
      vehiclePartManufacturersUrl,
      { partModelId: vehiclePartId[0] },
      authToken
    )
      .then((response) => {
        setManufacturerData([
          { key: 1, label: "Id", children: response.data.id },
          { key: 2, label: "Name", children: response.data.name },
          { key: 3, label: "E-mail", children: response.data.email },
          { key: 4, label: "PhoneNumber", children: response.data.phoneNumber },
          {
            key: 5,
            label: "organisationType",
            children: response.data.organisationType,
          },
        ]);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (vehiclePartId.length !== 0) {
      getManufacturersDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclePartId]);

  return (
    <div className="manufacturer-details">
      {manufacturerData?.length !== 0 ? (
        <>
          <Descriptions items={manufacturerData} />
        </>
      ) : (
        <Empty className="empty-value" />
      )}
    </div>
  );
};

export default ManufacturesTab;
