import React, { useEffect, useState } from "react";
import { Col, Row, Table, Button, Modal, Form } from "antd";
import "./style.css";
import { BUTTONS, FORMS } from "../common/constants";
import { getApis, postApis, putApis } from "../common/api";
import {
  createOrganisationUrl,
  organisationsUrl,
  updateOrganisationStatusUrl,
} from "../common/endpoints";
import CreateOrganistionForm from "./form";
import { getOrganisationFormValues } from "./getOrgFormValues";
import { toast } from "react-toastify";
import columnsData from "./columns";

const Organisations = () => {
  const authToken = localStorage.getItem("authToken");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleSwitchChange = async (id, checked) => {
    await putApis(
      updateOrganisationStatusUrl,
      {
        orgId: id,
        status: checked,
      },
      authToken
    );

    const updatedData = organisations.map((item) => {
      return item.id === id ? { ...item, status: checked } : item;
    });
    setOrganisations(updatedData);
  };

  const columns = columnsData(handleSwitchChange);

  const getOrganisations = async () => {
    await getApis(organisationsUrl, {}, authToken)
      .then((response) => {
        setOrganisations(response?.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    await postApis(
      createOrganisationUrl,
      getOrganisationFormValues(values),
      authToken
    )
      .then((response) => {
        if(response?.data?.error === null) {
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message)
        }
      })
      .catch((error) => toast.error(error));
    setIsLoading(false);
    setModalOpen(false);
  };

  return (
    <>
      <Row justify="space-between" className="flex-center">
        <Col span={4} className="head">
          Organisations
        </Col>
        <Col span={3}>
          <Button type="primary" onClick={() => setModalOpen(true)}>
            {BUTTONS.ADD_NEW}
          </Button>
        </Col>
      </Row>
      <Row justify="center" className="mt1">
        <Col span={24}>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={organisations}
            pagination={organisations.length > 10 ? { pageSize: 10 } : false}
            scroll={{ x: "100%" }}
          />
        </Col>
      </Row>
      <Modal
        title={FORMS.ORGANISATIONS.HEADER}
        centered
        open={modalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateOrganistionForm
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </Modal>
    </>
  );
};

export default Organisations;
