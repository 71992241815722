import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import GrafanaDashBoard from "./components/GrafanaDashboard";
import AuthLayout from "./components/AuthLayout";
import { useSelector } from "react-redux";
import Profile from "./components/Profile";
import Organisations from "./components/Organisations";
import VehicleModel from "./components/VehicleModel";
import ForgotPassword from "./components/ForgotPassword";
import Logout from "./components/common/Logout";
import { ROUTES } from "./components/common/constants";
import RegistrationConfirm from "./components/RegistrationConfirm";

const Routing = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const renderIfLoggedIn = (component) => {
    return isLoggedIn ? (
      <AuthLayout component={component} />
    ) : (
      <Navigate to={ROUTES.LOGIN} replace={true} />
    );
  };

  const renderIfNotLoggedIn = (component) => {
    return !isLoggedIn ? (
      component
    ) : (
      <Navigate to={ROUTES.DASHBOARDS} replace={true} />
    );
  };

  return (
    <Routes>
      <Route
        path={ROUTES.DASHBOARDS}
        exact
        element={renderIfLoggedIn(<GrafanaDashBoard />)}
      />
      <Route
        path={ROUTES.PROFILE}
        exact
        element={renderIfLoggedIn(<Profile />)}
      />
      <Route
        path={ROUTES.ORGANISATIONS}
        exact
        element={renderIfLoggedIn(<Organisations />)}
      />
      <Route
        path={ROUTES.VEHICLEMODEL}
        exact
        element={renderIfLoggedIn(<VehicleModel />)}
      />
      <Route
        path={ROUTES.LOGOUT}
        exact
        element={renderIfLoggedIn(<Logout />)}
      />

      <Route path={ROUTES.LOGIN} element={renderIfNotLoggedIn(<Login />)} />
      <Route
        path={ROUTES.FORGOT_PASSWORD}
        element={renderIfNotLoggedIn(<ForgotPassword />)}
      />
      <Route
        path={ROUTES.CONFIRM_REGISTRATION}
        element={renderIfNotLoggedIn(<RegistrationConfirm />)}
      />
    </Routes>
  );
};

export default Routing;
