export const ROUTES = {
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgotPassword",
  DASHBOARDS: "/dashboards",
  PROFILE: "/profile",
  ORGANISATIONS: "/organisations",
  VEHICLEMODEL: "/vehicleModel",
  LOGOUT: "/logout",
  CONFIRM_REGISTRATION: "/registrationConfirm",
};

export const HEADERS = {
  LOGIN: "Login to your account",
  FORGOT_PASSWORD: "Forgot Password?",
  PROFILE: "Profile",
  LOGOUT: "Logout",
  DASHBOARDS: "Dashboards",
};

export const FOOTER = {
  PREFIX_TEXT: "Grafana Admin ©",
  SUFFIX_TEXT: "Created by NicheSolv",
};

export const BUTTONS = {
  MAP_IT: "Map It",
  SAVE: "Save",
  ADD_NEW: "Add New",
  CREATE: "Create",
  NO: "No",
  YES: "Yes",
  REPUBLISH: "Republish",
  REPUBLISH_ALL: "Republish All",
};

export const ORGANISATION_TYPE = {
  ADMINISTRATOR: "ADMINISTRATOR",
  MANUFACTURER: "MANUFACTURER",
  DEALER: "DEALER",
  B2BCUSTOMER: "B2BCUSTOMER",
  B2CCUSTOMER: "B2CCUSTOMER",
};

export const FORMS = {
  PROFILE: {
    NAME: "Name",
    EMAIL: "Email",
    ORGANISATION: "Organisation",
    ROLE: "Role",
    PHONE_NUMBER: "Phone Number",
  },
  DASHBOARDS: {
    HEADER: "Map Dashboard to an Organisation",
    MAP_CONFIRM: "Are you sure you want to map it?",
    FIELDS: {
      DASHBOARD_NAME: "Dashboard Name",
      ORGANISATION_TYPE: "Organisation Type",
    },
  },
  ORGANISATIONS: {
    HEADER: "Create Organisation",
    FIELDS: {
      NAME: { LABEL: "Name", NAME: "name" },
      EMAIL: { LABEL: "Email", NAME: "email" },
      PHONE_NUMBER: { LABEL: "Phone Number", NAME: "phoneNumber" },
      DESCRIPTION: { LABEL: "Description", NAME: "description" },
      ADDRESS1: { LABEL: "Address1", NAME: "address1" },
      ADDRESS2: { LABEL: "Address2", NAME: "address2" },
      CITY: { LABEL: "City", NAME: "city" },
      ADMIN_AREA: { LABEL: "Admin Area", NAME: "adminArea" },
      POSTAL_CODE: { LABEL: "Postal Code", NAME: "postalCode" },
      COUNTRY: { LABEL: "Country", NAME: "country" },
      LINK_ORGANISATION_ID: {
        LABEL: "Link Organisation Id",
        NAME: "linkOrganisationId",
        PLACEHOLDER: "Link Organisation",
      },
      ORGANISATION_TYPE: {
        LABEL: "Organisation Type",
        NAME: "organisationType",
        PLACEHOLDER: "Select Organisation Type",
      },
      IS_COMMERCIAL: { LABEL: "Is Commercial", NAME: "isCommercial" },
      URL_SLUG: { LABEL: "Url Slug", NAME: "urlSlug" },
      IS_LOGIN_ENABLED: {
        LABEL: "Is Login Enabled?",
        NAME: "isLoginEnabled",
      },
    },
    FIELDSB2CCUSTOMER: {
      FIRSTNAME: { LABEL: "FirstName", NAME: "fname" },
      LASTNAME: { LABEL: "LastName", NAME: "lname" },
    },
  },
  VALIDATION_ERRORS: {
    PHONE_NUMBER_VALID: "Please enter a valid phone number!",
    PHONE_NUMBER_DIGIT: "Please enter a 10-digit phone number.",
    POSTAL_CODE: "Postal Code must be a number!",
  },
};
