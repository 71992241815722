import React, { useCallback, useEffect, useState } from "react";
import { Col, Layout, Row, Button, Modal, Form, Table, message } from "antd";
import { BUTTONS, FORMS, HEADERS } from "../common/constants";
import MapForm from "./form";
import "./style.css";
import { getApis, postApis, putApis } from "../common/api";
import {
  dashboardsUrl,
  mapDashboardToOrgUrl,
  republishAllDashboardUrl,
  republishDashboardUrl,
} from "../common/endpoints";
import { useDispatch } from "react-redux";
import { setMenu } from "../../reducers/authSlice";

const GrafanaDashBoard = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const authToken = localStorage.getItem("authToken");
  const [dashboards, setDashboards] = useState([]);
  const dispatch = useDispatch();
  const [confirmMap, setConfirmMap] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const getOrganisations = useCallback(async () => {
    await getApis(dashboardsUrl, {}, authToken)
      .then((response) => setDashboards(response?.data))
      .catch((error) => console.log(error));
  }, [authToken]);

  const handleCancel = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    setFormValues(values);
    setConfirmMap(true);
  };

  const handleMapConfirm = () => {
    postApis(mapDashboardToOrgUrl, formValues, authToken);
    setConfirmMap(false);
    setModalOpen(false);
  };

  const handleMapCancel = () => {
    setConfirmMap(false);
  };

  const handleRepublishAll = () => {
    putApis(republishAllDashboardUrl, {}, authToken).then((response) =>
      showPublishMessage(response.data.message)
    );
  };

  const handleRepublish = (dashboard) => {
    console.log(dashboard);
    putApis(republishDashboardUrl, { uid: dashboard.uid }, authToken).then(
      (response) => showPublishMessage(response.data.message)
    );
  };

  const showPublishMessage = (message) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Organisations",
      dataIndex: "organisationTypes",
      key: "organisationTypes",
      render: (record) =>
        record.map((r) => `${r.organisationType} - ${r.id}`).join(", "),
    },
    {
      title: "",
      dataIndex: "republish",
      key: "republish",
      render: (text, dashboard) => (
        <Button
          type="primary"
          disabled
          onClick={() => handleRepublish(dashboard)}
        >
          {BUTTONS.REPUBLISH}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getOrganisations();
    dispatch(setMenu("dashboards"));
  }, [dispatch, getOrganisations]);

  return (
    <>
      {contextHolder}
      <Layout>
        <Row className="flex-center mb1" gutter={[16, 24]}>
          <Col className="head" xl={19} lg={18} md={16} sm={14} xs={24}>
            {HEADERS.DASHBOARDS}
          </Col>
          <Col xl={2} lg={3} md={4} sm={5} xs={12}>
            <Button type="primary" onClick={() => setModalOpen(true)}>
              {BUTTONS.MAP_IT}
            </Button>
          </Col>
          <Col xl={3} lg={3} md={4} sm={5} xs={12}>
            <Button type="primary" disabled onClick={() => handleRepublishAll()}>
              {BUTTONS.REPUBLISH_ALL}
            </Button>
          </Col>
        </Row>

        <Row justify="center" className="mt1">
          <Col span={24}>
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={dashboards}
              pagination={dashboards.length > 10 ? { pageSize: 10 } : false}
              scroll={{ x: "100%" }}
            />
          </Col>
        </Row>
        {confirmMap ? (
          <Modal
            title={FORMS.DASHBOARDS.MAP_CONFIRM}
            centered
            visible={modalOpen}
            onCancel={handleMapCancel}
            footer={[
              <Button
                key="no"
                type="primary"
                className="map-modal-button-no"
                onClick={() => setConfirmMap(false)}
              >
                {BUTTONS.NO}
              </Button>,
              <Button key="yes" type="primary" onClick={handleMapConfirm}>
                {BUTTONS.YES}
              </Button>,
            ]}
          ></Modal>
        ) : (
          <Modal
            title={FORMS.DASHBOARDS.HEADER}
            centered
            open={modalOpen}
            onOk={form.submit}
            onCancel={handleCancel}
            footer={null}
          >
            <MapForm handleSubmit={handleSubmit} dashboards={dashboards} />
          </Modal>
        )}
      </Layout>
    </>
  );
};

export default GrafanaDashBoard;
