import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMenu } from "../../reducers/authSlice";

const Navbar = () => {
  const menu = useSelector((state) => state.user.menu);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenu = (e) => {
    dispatch(setMenu(e.key));
    navigate(`/${e.key}`);
  };

  const menuItems = [
    {
      label: "Dashboards",
      key: "dashboards",
    },
    {
      label: "Organisations",
      key: "organisations",
    },
    {
      label: "Vehicle Model",
      key: "vehicleModel",
    },
  ];

  return (
    <Menu
      onClick={(e) => handleMenu(e)}
      selectedKeys={[menu]}
      mode="horizontal"
      className="navbar-menu"
      items={menuItems}
    />
  );
};

export default Navbar;
