import React, { useState } from "react";
import { Select, Button, Form } from "antd";
import { ORGANISATION_TYPE, FORMS, BUTTONS } from "../common/constants";

const MapForm = ({ handleSubmit, dashboards }) => {
  const { Option } = Select;
  const [optionSelected, setOptionSelected] = useState();

  const getFormFieldRule = (field) => {
    const rule = {
      required: true,
      message: `Please input ${field}!`,
    };
    return rule;
  };

  const handleChange = (value) => {
    setOptionSelected(value);
  };

  const organisationType = [
    ORGANISATION_TYPE.ADMINISTRATOR,
    ORGANISATION_TYPE.MANUFACTURER,
    ORGANISATION_TYPE.DEALER,
    ORGANISATION_TYPE.B2BCUSTOMER,
    ORGANISATION_TYPE.B2CCUSTOMER,
  ];
  return (
    <Form
      onFinish={handleSubmit}
      autoComplete="off"
      className="mt2"
      initialValues={{ dashBoardName: optionSelected }}
    >
      <Form.Item
        label={FORMS.DASHBOARDS.FIELDS.DASHBOARD_NAME}
        name="id"
        rules={[getFormFieldRule(FORMS.DASHBOARDS.FIELDS.DASHBOARD_NAME)]}
      >
        <Select
          placeholder={FORMS.DASHBOARDS.FIELDS.DASHBOARD_NAME}
          onChange={handleChange}
          size="large"
          data-testid="dashboard-select"
          options={dashboards.map((i) => ({
            label: i.title,
            value: i.id,
            key: i.id,
          }))}
        ></Select>
      </Form.Item>
      <Form.Item
        name="organisationTypes"
        label={FORMS.DASHBOARDS.FIELDS.ORGANISATION_TYPE}
        rules={[getFormFieldRule(FORMS.DASHBOARDS.FIELDS.ORGANISATION_TYPE)]}
      >
        <Select placeholder="Select Organisation" mode="multiple" size="large">
          {organisationType.map((type, index) => (
            <Option
              key={index}
              value={type}
              disabled={type === ORGANISATION_TYPE.ADMINISTRATOR}
            >
              {type}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Button type="primary" htmlType="submit">
        {BUTTONS.SAVE}
      </Button>
    </Form>
  );
};

export default MapForm;
