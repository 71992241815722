import { HEADERS } from "./constants";

export const setUserDetails = (data) => {
  localStorage.setItem("authToken", data?.authToken);
  const userDetails = {
    email: data?.emailAddress,
    firstName: data?.firstName,
    lastName: data?.lastName,
    phoneNumber: data?.phoneNumber,
    organisations: data?.organisations,
    roles: data?.roles,
    currentRole: data?.roles[0],
    currentOrganisation: data?.organisations[0],
  };
  localStorage.setItem("userDetails", JSON.stringify(userDetails));
};

export const getItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

export const userPages = () => {
  const items = [getItem(HEADERS.PROFILE, "profile", "")];
  items.push({ type: "divider" });
  items.push(getItem(HEADERS.LOGOUT, "logout", ""));
  return items;
};
