export const getOrganisationFormValues = (values) => {
  return {
    name:
      values.name !== undefined
        ? values.name
        : values.fname + " " + values.lname,
    email: values.email,
    phoneNumber: values.phoneNumber,
    description:
      values.description !== undefined
        ? values.description
        : values.organisationType,
    logo: "",
    type: [],
    address: {
      addressLine1: values.address1,
      addressLine2: values.address2,
      city: values.city,
      adminArea: values.adminArea,
      postalCode: values.postalCode,
      country: values.country,
    },
    linkedOrganisationId: [],
    organisationType: values.organisationType,
    isCommercial: values.isCommercial !== undefined,
    urlSlug: values.urlSlug ? values.urlSlug : values.organisationType,
    isLoginEnabled: values.isLoginEnabled !== undefined,
    userDetails: {
      firstName: values.fname,
      lastName: values.lname,
      email: values.email,
      phoneNumber: values.phoneNumber,
    },
  };
};
