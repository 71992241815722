import { FORMS } from "../common/constants";
import { Form, Input } from "antd";

const BusinessToCustomerForm = ({
  getFormFieldRule,
  validationStatus,
  handleKeyPress,
}) => {
  const formFields = [
    {
      label: FORMS.ORGANISATIONS.FIELDSB2CCUSTOMER.FIRSTNAME.LABEL,
      name: FORMS.ORGANISATIONS.FIELDSB2CCUSTOMER.FIRSTNAME.NAME,
      rules: getFormFieldRule(
        FORMS.ORGANISATIONS.FIELDSB2CCUSTOMER.FIRSTNAME.LABEL
      ),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDSB2CCUSTOMER.LASTNAME.LABEL,
      name: FORMS.ORGANISATIONS.FIELDSB2CCUSTOMER.LASTNAME.NAME,
      rules: getFormFieldRule(
        FORMS.ORGANISATIONS.FIELDSB2CCUSTOMER.LASTNAME.LABEL
      ),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.EMAIL.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.EMAIL.NAME,
      style: {},
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.PHONE_NUMBER.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.PHONE_NUMBER.NAME,
      rules: getFormFieldRule(
        FORMS.ORGANISATIONS.FIELDS.PHONE_NUMBER.LABEL,
        /^[0-9]{10}$/,
        FORMS.VALIDATION_ERRORS.PHONE_NUMBER_DIGIT
      ),
      style: {},
      help: !validationStatus.phoneNumber
        ? FORMS.VALIDATION_ERRORS.PHONE_NUMBER_VALID
        : null,
      keyType: FORMS.ORGANISATIONS.FIELDS.PHONE_NUMBER.NAME,
      hasFeedback: true,
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.ADDRESS1.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.ADDRESS1.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.ADDRESS1.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.ADDRESS2.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.ADDRESS2.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.ADDRESS2.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.CITY.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.CITY.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.CITY.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.ADMIN_AREA.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.ADMIN_AREA.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.ADMIN_AREA.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.POSTAL_CODE.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.POSTAL_CODE.NAME,
      rules: getFormFieldRule(
        FORMS.ORGANISATIONS.FIELDS.POSTAL_CODE.LABEL,
        /^[0-9]+$/,
        FORMS.VALIDATION_ERRORS.POSTAL_CODE
      ),
      style: { display: "inline-block", width: "50%" },
      help: !validationStatus.postalCode
        ? FORMS.VALIDATION_ERRORS.POSTAL_CODE
        : null,
      keyType: FORMS.ORGANISATIONS.FIELDS.POSTAL_CODE.NAME,
      hasFeedback: true,
    },
    {
      label: FORMS.ORGANISATIONS.FIELDS.COUNTRY.LABEL,
      name: FORMS.ORGANISATIONS.FIELDS.COUNTRY.NAME,
      rules: getFormFieldRule(FORMS.ORGANISATIONS.FIELDS.COUNTRY.LABEL),
      style: { display: "inline-block", width: "50%" },
    },
  ];

  return (
    <>
      {formFields.map((field, index) => (
        <Form.Item
          key={index}
          label={field.label}
          name={field.name}
          rules={field.rules}
          style={field.style}
          help={field.help}
        >
          <Input onKeyPress={(e) => handleKeyPress(e, field.keyType)} />
        </Form.Item>
      ))}
    </>
  );
};

export default BusinessToCustomerForm;
