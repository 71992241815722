import Layout, { Content } from "antd/es/layout/layout";
import { Header } from "antd/es/layout/layout";
import "./style.css";
import { Dropdown, Space } from "antd";
import { Footer } from "antd/es/layout/layout";
import Navbar from "./Navbar";
import { ROUTES, FOOTER } from "../common/constants";
import { Link, useNavigate } from "react-router-dom";
import { userPages } from "../common/utils";
import { CaretDownFilled } from "@ant-design/icons";
import AVATAR from "../assets/images/icons/avatar.svg";
import { useOrganization } from "../../OrganizationProvider";

const AuthLayout = ({ component }) => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const organization = useOrganization();

  const currentYear = new Date().getFullYear();

  const onDropdownClick = (e) => {
    navigate(`/${e.key}`);
  };

  return (
    <Layout className="auth-layout">
      <Header className="header">
        <div className="header-links">
          <Link to={ROUTES.DASHBOARDS}>
            <img
              src={`${organization.s3BucketBaseUrl}/InnerLogo.svg`}
              alt="Logo"
              className="niche_logo"
            />
          </Link>
        </div>
        <Dropdown
          menu={{
            onClick: onDropdownClick,
            items: userPages(),
          }}
        >
          <Space>
            <img src={AVATAR} alt="avatar" className="icon-margin" />
            {userDetails?.firstName}
            <CaretDownFilled />
          </Space>
        </Dropdown>
      </Header>
      <Navbar />
      <Content className="auth-layout-content">{component}</Content>
      <Footer className="footer">
        <p className="footer-text">
          {`${FOOTER.PREFIX_TEXT} ${currentYear} ${FOOTER.SUFFIX_TEXT}`}
        </p>
      </Footer>
    </Layout>
  );
};

export default AuthLayout;
