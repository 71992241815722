import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: localStorage.getItem("authToken") !== null,
    menu: window.location.pathname.split("/")[1],
  },
  reducers: {
    setLoggedIn: (state) => {
      return {
        ...state,
        isLoggedIn: true,
      };
    },
    setLoggedOut: (state) => {
      return {
        ...state,
        isLoggedIn: false,
      };
    },
    setMenu: (state, action) => {
      return {
        ...state,
        menu: action.payload,
      };
    },
  },
});

export const { setLoggedIn, setLoggedOut, setMenu } = authSlice.actions;

export default authSlice.reducer;
