import React from "react";
import { Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";

const TreeView = ({
  treedata,
  handleTreeViewSelect,
  treeViewSelectedValue,
}) => {

  const tree = treedata.map((part, index) => ({
      title: part.name,
      value: part.id,
      key: part.id,
      children: Object.keys(part.parts).map((key) => ({
        title: key,
        value: part.parts[key][0].id,
        key: key,
        children: part.parts[key].map((childPart) => ({
          title: childPart.name,
          value: childPart.id,
          key: childPart.id,
        }))
      }))
  }))

  return (
    <Tree
      onSelect={handleTreeViewSelect}
      showLine
      switcherIcon={<DownOutlined />}
      defaultExpandAll
      selectedKeys={treeViewSelectedValue}
      treeData={tree}
    />
  );
};

export default TreeView;
