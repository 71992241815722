import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoggedOut } from "../../reducers/authSlice";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    dispatch(setLoggedOut());
    navigate("/login");
  }, [dispatch, navigate]);

  return <></>;
};

export default Logout;
