import "./style.css";
import { NicheJwtLogin } from "niche-ui-component";
import { userJwtLoginUrl } from "../common/endpoints";
import { userJwtLoginSchemaUrl } from "../common/endpoints";
import { Col, Row } from "antd";
import { ROUTES, HEADERS } from "../common/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../reducers/authSlice";
import { setUserDetails } from "../common/utils";
import Layout, { Content } from "antd/es/layout/layout";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (response) => {
    dispatch(setLoggedIn());
    setUserDetails(response?.data);
    navigate(ROUTES.DASHBOARDS);
  };

  return (
    <Layout className="login-layout">
      <Content className="flex-layout">
        <div className="login-form">
          <p className="login-form-title">{HEADERS.LOGIN}</p>
          <Row justify="center">
            <Col span={24} className="mb1">
              <NicheJwtLogin
                handleSubmit={handleSubmit}
                url={userJwtLoginUrl}
                schemaUrl={userJwtLoginSchemaUrl}
                linkText="Forgot Password?"
                linkUrl="/forgotPassword"
              />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default Login;
