import React from "react";
import { Switch } from "antd";

const columnsData = (handleSwitchChange) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Linked Organisations",
      dataIndex: "linkedOrganisationId",
      key: "linkedOrganisationId",
      render: (ids) => ids?.map((id) => id).join(", "),
    },
    {
      title: "Org. Type",
      dataIndex: "organisationType",
      key: "organisationType",
    },
    {
      title: "Commercial?",
      dataIndex: "isCommercial",
      key: "isCommercial",
      render: (record) => (record === true ? "Yes" : "No"),
    },
    {
      title: "Login Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (record) => (record === true ? "Yes" : "No"),
    },
    {
      title: "Is Active?",
      dataIndex: "",
      key: "x",
      render: (record) => (
        <Switch
          className="delete-switch"
          defaultChecked={record.status}
          onChange={(checked) => handleSwitchChange(record.id, checked)}
        />
      ),
    },
  ];
};

export default columnsData;
